.App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 50%;
    max-width: 1000px;
    min-width: 300px;
  }
}
